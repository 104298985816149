import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aquarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Aquarius Love
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aquarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aquarius </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aquarius Love</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aquarius Love</h1>
                <h2 className="text-sm md:text-base ml-4">Jan 20 - Feb 18</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

            </div>
            <span className="my-4 mx-1 md:mx-0">
            You are in a social group where everyone's having a great time catching up with you. Mid-everyone giggling and appreciating, maybe your aunt or uncle blurts out, "Don't you wanna get married?" And only half-joking, they also understand the individual who would be the perfect match for you, and you should satisfy them on time before you say 'nay.' You might feel that they won't just be kidding about it soon. In today's generation, an arranged marriage does not hold much attraction. However, it is a fact that arranged marriages are still famous in many parts of the world. Which begs the question: Will I have a love marriage or arranged marriage?<br/>
            <br></br>
            Marriage is a life-long responsibility, and you cannot leave it to chance. Thus, choosing the right life partner is a vital key to happiness. There are two routes to getting married: love marriage or an arranged one. In a love marriage, you marry a person you fall in love with, and in an arranged marriage, probably your family members. You search through different sources to select that perfect match that meets your expectations and preferences.
            Astrology can help you make your arranged marriage prosperous with the help of  matching of kundali by date of birth.<br/>
            <br></br>
            It is also true that you will marry the person you are destined to be with. And Astrology of marriage can help you figure out what fate has in store for you. Ready to take a leap of faith and find out what awaits your married life? If yes, talk to Astrologer and get a personalized resolution to your situation.  <br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Astrological Indicators of Love or Arranged Marriage</p><br/>
            All twelfth houses of the Vedic Birth chart signify a specific part of your life. The fifth house is the house of love, and the seventh house is the house of marriage in a horoscope. The second, fifth, and eighth houses also affect some factors of your married life. The arrangement of the planets Venus, Jupiter, and Mars also affects your married life.<br/>
            <br></br>
            To investigate the Yoga (planetary combination) for Love marriage, it is essential to specify the strength of the fifth house and its Lord in your Kundli. If the fifth house and its Lord are substantial and without distress, chances of love marriage will increase, as per the Indian Vedic astrology. Also, if emotional solid planets occupy the 5th house, there are possibilities of a love relationship.<br/>
            <br></br>
            The relation or association between the fifth house and seventh house is essential to judge the love marriage prospects. Also, the aspect or intersection between Venus and Mars must be examined. Primarily, the 5th and 7th houses and the Venus-Mars relations signify the possibility of love or arranged marriage. However, the ninth house ( house of fortune) and its Lord also play an essential role in this matter. The more profitable and promising your ninth house is, the happier will your married life be.<br/>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Also, there are three other factors to be considered:</p><br/>
            1.	The Potential in your Birth Chart<br/>
            2.	The influence of the Mahadasha and Antardasha<br/>
            3.	The impacts of the transiting planets<br/>
            <br></br>
            Jupiter is Karaka for matrimony with tradition; hence powerful Jupiter or its impact on the 7th house or Lord suggests traditional happy marriage.<br/>
            Curious to know whether you will have an arranged marriage or a love marriage? Are you interested to learn more about your love life? Ask our best astrologer, who is all ready to give answers to your questions.<br/> 
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Wrapping up</p><br/>
            Marriage is an essential social association, and every individual wants to have an ideal match. Both arranged, and love marriages have their advantages and disadvantages. And, indeed, success doesn't depend upon whether it was a love marriage or set by your family. Still, it depends on how you care for each other, respect each other, and how the two people understand each other and support each other. Astrology acts as a helping hand for you to find the ideal compatible partner based on your birth chart. <br/>
            <br></br>
            Check your free daily horoscope, weekly horoscope, monthly horoscope, or yearly horoscope.<br/>
            <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
   